<template>
  <div class="report-view task-time-sheet-report">
    <div
      v-if="exportLoader"
      class="report-loader-screen"
    >
      <div class="d-flex align-items-center justify-content-center h-100 overdlow-sheet">
        <img
          src="../../../public/loader.gif"
          alt=""
        >
      </div>
    </div>
    <div
      v-if="!isEditTimesheetReport"
      class="viewEdit ttrInfo"
    >
      <!-- start date picker toggle -->
      <b-card class="d-flex">
        <div class="calendar justify-content-between mt-0">
          <p class="task-timesheet">
            Task Timesheet Report
          </p>
          <div
            id="date-select"
            class="d-flex justify-content-center align-items-center mr-0 calender"
          >
            <VueDatePicker
              :start="startDate"
              :end="endDate"
              :active-btn="activeBtn"
              @date="updateDate"
            />
            <Help
              v-show="$store.state.app.userManualList"
              code="TR"
            />
          </div>
        </div>
      </b-card>
      <!-- end date picker toggle -->

      <!-- start Filters & export -->
      <div class="card-filter-section mt-0">
        <b-row>
          <b-col
            cols="8"
            lg="8"
            class="d-block d-lg-flex align-items-center justify-content-start flex-wrap"
          > <button
              class="back-button btn btn-secondary mr-2"
              @click="back()"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                width="18"
                height="18"
              />
              <span>Back</span>
            </button>
            <p class="filter-title">
              Filters:
            </p>
            <div class="w-lg-100">
              <custom-dropdown
                v-model="searchProjects"
                :options="filterProjectOptions"
                label="Project"
                multiple
              />
            </div>

            <div>
              <custom-dropdown
                v-model="searchUser"
                :options="filterUserOptions"
                multiple
                label="Members"
              />
            </div>
            <div>
              <custom-dropdown
                v-model="searchTags"
                :options="$store.state.app.tagList"
                multiple
                label="Tags"
              />
            </div>
            <div v-if="userInfo && (userInfo.role === 'SA' || userInfo.is_resource_managing)">
              <custom-dropdown
                v-model="searchBillable"
                :options="billable_list"
                label="Billable"
                :search-box="false"
              />
            </div>
            <div
              v-if="userInfo && userInfo.role === 'SA'"
              class=""
            >
              <custom-dropdown
                v-model="groupId"
                :options="$store.state.app.groupList"
                label="Group"
              />
            </div>
            <div
              v-if="userInfo && userInfo.role === 'SA'"
              class=""
            >
              <custom-dropdown
                v-model="client_id"
                :options="clientListOption"
                multiple
                label="Client"
              />
            </div>
            <OptionDropdown v-model="perPage" />
          </b-col>
          <b-col
            cols="4"
            lg="4"
            class="d-flex justify-content-end"
          >
            <button
              v-if="!isExportBtnEnable"
              class="timesheet-edit-button btn btn-secondary mr-2"
              @click="isEditTimesheetReport = true"
            >
              <span>Edit Task</span>
              <feather-icon
                icon="EditIcon"
                width="18"
                height="18"
                class="ml-25"
              />
            </button>
            <button
              class="apply-filter filterRedesign mr-1"
              @click="applyFilter()"
            >
              Apply Filter
            </button>
            <button
              class="apply-filter-clear filterRedesign mr-1"
              @click="clearFilter()"
            >
              Clear
            </button>
            <button
              v-if="userInfo.role == 'SA' || userInfo.is_resource_managing == 1"
              v-show="false"
              class="apply-filter filterRedesign mr-1"
              @click="isSchedularModalOpen = true"
            >
              Schedule
            </button>
            <b-dropdown
              id="dropdown-right"
              right
              text="Export"
              class="custom-drop-timesheet-report filterRedesign side-bar-clear"
              :class="{ 'dropdown-disabled': isExportBtnEnable }"
              :disabled="isExportBtnEnable"
            >
              <template #button-content>
                <div class="d-flex align-items-center">
                  <span class="mr-25 fw-600">Export</span>
                  <feather-icon
                    icon="ChevronDownIcon"
                    width="18"
                    height="18"
                  />
                </div>
              </template>
              <b-dropdown-item @click="confirmationFunction('PDF')">
                PDF
              </b-dropdown-item>
              <b-dropdown-item @click="confirmationFunction('CSV')"> XLSX
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
      </div>
      <!-- end Filters & export -->

      <p class="total-hr-timespent">
        Total Hours Spent : <span>{{ totalDailyTime }}</span>
      </p>

      <div class="report-task-section">
        <list-loader
          v-if="loader && reportsList.length == 0"
          :fields="fields"
        />
        <div v-else-if="reports.length">
          <div class="emp-list time-sheet-list tableDesigns">
            <b-table
              :items="reportsList"
              :fields="filteredFields"
              show-empty
              reponsive
              class="emp-list-data emp-list-report table-responsive time-sheet-report"
            >
              <template #cell(name)="data">
                <div class="d-flex align-items-center new-time-report">
                  <b-avatar
                    size="30"
                    :src="data.item.picture"
                    :text="data.item.first_name | avatarText"
                    style="background-color: #f97753"
                  />

                  <div class="emp-detail">
                    <p class="emp-name">
                      {{ data.item.full_name ? data.item.full_name : "" }}
                    </p>
                  </div>
                </div>
              </template>
              <template #cell(project)="data">
                <div class="d-flex align-items-center new-time-report">
                  <b-avatar
                    size="30"
                    :text="data.item.project_name
                      ? data.item.project_name.charAt(0)
                      : 'Z'
                    "
                    :style="[
                      data.item.color
                        ? { background: `#${data.item.color}` }
                        : '',
                    ]"
                  />
                  <div class="emp-detail">
                    <p
                      v-b-tooltip.hover.top="data.item.project_name"
                      class="emp-name"
                      :style="[
                        data.item.color ? { color: `#${data.item.color}` } : '',
                      ]"
                    >
                      {{ data.item.project_name }}
                    </p>
                  </div>
                </div>

              </template>
              <template #cell(date)="data">

                <div class="d-flex align-items-center">
                  <p class="date">
                    {{ data.item.date | DateDDMMYYYYFormat }}
                  </p>
                </div>

              </template>
              <template #cell(desc)="data">

                <div class="align-items-center d-inline-block">
                  <p
                    class="timesheetReportDesc"
                    style="word-break: keep-all"
                  >
                    <v-clamp
                      autoresize
                      :max-lines="5"
                      ellipsis="..."
                      location="end"
                      class="py-1"
                      :style="{ whiteSpace: 'pre-line' }"
                    >
                      {{ data.item.user_task_title
                      }}
                      <template #after="{ toggle, clamped, expanded }">
                        <button
                          v-if="clamped === true || expanded === true"
                          class="see-more-btn"
                          @click="toggle"
                        >
                          {{ clamped === true ? "see more" : "see less" }}
                        </button>
                      </template>
                    </v-clamp>
                  </p>
                </div>
              </template>

              <template #cell(tag)="data">
                <div>
                  <div
                    v-for="(tag, index) in tagsArray(data.item.tags)"
                    :key="index"
                  >
                    {{ tag
                    }}{{
                      tagsArray(data.item.tags).length - 1 === index ? null : " ,"
                    }}
                  </div>
                </div>

              </template>
              <template #cell(start_time)="data">

                <div>
                  <div
                    v-if="data.item.start_time"
                    class="align-items-center"
                  >
                    {{ data.item.start_time | formatTimeHHMM }}

                  </div>
                </div>

              </template>
              <template #cell(end_time)="data">
                <div>
                  <div
                    v-if="data.item.end_time"
                    class="align-items-center"
                  >
                    {{ data.item.end_time | formatTimeHHMM }}
                  </div>
                </div>
              </template>

              <template #cell(is_billable)="data">
                <div class="align-items-center">
                  <div
                    :class="[
                      data.item.is_billable ? 'billable' : 'nonBillable',
                    ]"
                  >
                    <span
                      @click="
                        userInfo.is_resource_managing || userInfo.role === 'SA'
                          ? updateTaskBillable(data.item)
                          : null
                      "
                    >$</span>
                  </div>
                </div>
              </template>

              <template #cell(Duration)="data">
                <div class="align-items-center">
                  {{ data.item.count_minutes | formatTimeHHMM }}

                </div>
              </template>

            </b-table>
            <!-- pagination section -->
            <Pagination
              v-if="totalCount > 0"
              :current-page="currentPage"
              :per-page="perPage"
              :total-count="totalCount"
              class="report-pagination"
              @currentPage="currentPage = $event"
            />
            <!-- pagination section complete here -->
          </div>
        </div>
        <div
          v-else
          class="w-100"
        >
          <img
            src="@/assets/images/nodatafound/nodata.svg"
            alt="no-data"
            class="no-data-img"
          >
        </div>
      </div>
    </div>
    <div v-else>
      <time-sheet-report-edit @closeEditDataPage="isEditTimesheetReport = false" />
    </div>
    <AddEditScheduledReport
      :data="null"
      :filter="scheduleFilter"
      :is-schedular-modal-open-prop="isSchedularModalOpen"
      :from="'timesheet_report'"
      @updateSchedularModalOpen="isSchedularModalOpen = $event"
    />
  </div>
</template>

<script>
import * as moment from 'moment/moment'
import ListLoader from '@/components/loaders/table-loading.vue'
import customDropdown from '@/components/dropdown/customDropdown.vue'
import Help from '@/components/HelpDesk/Help.vue'
import VClamp from 'vue-clamp'
import OptionDropdown from '../optiondropdown/OptionDropdown.vue'
import { eventBus } from '../../main'
import VueDatePicker from '../../views/TaskSummary/calender.vue'
import TimeSheetReportEdit from './TimesheetReportEdit.vue'
import AddEditScheduledReport from './scheduleReports/AddEditScheduledReport.vue'

export default {
  name: 'HeaderBlock',
  components: {
    ListLoader,
    customDropdown,
    OptionDropdown,
    Help,
    VClamp,
    VueDatePicker,
    TimeSheetReportEdit,
    AddEditScheduledReport,
  },

  data() {
    return {
      loader: false,
      exportLoader: false,
      totalTime: null,
      totalCount: 0,
      currentPage: 1,
      perPage: 100,
      projects: [],
      users: [],
      startDate: moment().startOf('week').add(1, 'd').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      reports: [],
      searchUser: [],
      searchProjects: [],
      searchTags: [],
      billable_list: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
      ],
      searchBillable: null,
      groupId: null,
      client_id: [],
      IsCurrentPageOne: false,
      isSchedularModalOpen: false,
      fields: [
        {
          key: 'name',
          label: 'Name',
          thStyle: { width: '200px' },
          tdStyle: { with: '200px' },
        },
        {
          key: 'project',
          label: 'Project',

          thStyle: { width: '200px' },
          tdStyle: { with: '200px' },
          tdClass: 'custom-column',
        },

        {
          key: 'desc',
          label: 'description',
          thStyle: { minWidth: '350px' },
          tdStyle: { with: '350px' },
          tdClass: 'reportdesc',
        },
        {
          key: 'tag',
          label: 'Tags',
          thStyle: { width: '100px' },
          tdStyle: { with: '100px' },
          tdClass: 'date ',
        },

        {
          key: 'date',
          label: 'Date',
          thStyle: { width: '150px' },
          tdStyle: { with: '150px' },
          tdClass: '',
        },
        {
          key: 'start_time',
          label: 'Start Time',
          thStyle: { width: '180px' },
          tdStyle: { with: '180px' },
          tdClass: 'date',
        },
        {
          key: 'end_time',
          label: 'End Time',
          thStyle: { width: '180px' },
          tdStyle: { with: '180px' },
          tdClass: 'date',
        },
        {
          key: 'is_billable',
          label: 'Billable',
          thStyle: { maxWidth: '80px' },
          tdStyle: { with: '180px' },
          tdClass: 'date',
        },
        {
          key: 'Duration',
          label: 'Duration',
          tdClass: 'duration',
          // sortable: false,
          thStyle: { width: '80px' },
        },
      ],
      isEditTimesheetReport: false,
      activeBtn: (this.$route.query && this.$route.query.active) || null,
      reportingAndManageByUsers: [],
    }
  },
  computed: {
    scheduleFilter() {
      const filter = {
        is_billable: this.searchBillable || null,
        tags: this.searchTags || [],
        project: this.searchProjects || [],
        member: this.searchUser || [],
        date: (`${this.startDate} to ${this.endDate}`) || null,
        group_id: this.groupId || null,
        client_id: this.client_id || [],
      }
      return filter
    },
    clientListOption() {
      this.$store.state.app.clientList.forEach(element => {
        element.value = element.id
        element.label = element.name
      })

      return this.$store.state.app.clientList
    },
    reportsList() {
      return this.reports
    },
    totalDailyTime() {
      const total = this.totalTime
      return total ? this.secondsToTime(total * 60) : '00:00'
    },
    filterProjectOptions() {
      const allProject = []
      this.projects.forEach(element => {
        allProject.push({
          value: element.id,
          label: element.name,
          type: element.work_type,
        })
      })

      // Sort the allProject array alphabetically by project name (label)
      allProject.sort((a, b) => a.label.localeCompare(b.label))

      return allProject
    },

    filterUserOptions() {
      return this.sortedUser(this.users)
    },
    filteredFields() {
      if (this.userInfo.role === 'EMP' && !this.userInfo.is_resource_managing) {
        // Filter out the "is_billable" field if the user role is employee
        return this.fields.filter(field => field.key !== 'is_billable')
      }
      return this.fields
    },

    isExportBtnEnable() {
      if (this.searchBillable === 'no' || this.reports.length == 0) {
        return true
      }
      return false
    },
  },
  watch: {
    searchUser(nv) {
      this.IsCurrentPageOne = true

      /* project user */
      if (this.searchUser.length && !this.searchProjects.length) {
        this.UserProjectList()
      }
      if (!this.searchUser.length && this.searchProjects.length) {
        if (this.userInfo.role == 'EMP') {
          this.projectList()
          this.reportingAndManageByUser()
        }
      }

      if (this.userInfo && (this.userInfo.role === 'SA' || this.userInfo.is_resource_managing)) {
        /* user project both are null */
        if (!this.searchUser.length && !this.searchProjects.length) {
          this.projectList()
          if (nv && !nv.length && this.userInfo && this.userInfo.role === 'SA') {
            this.userList()
          } else {
            this.reportingAndManageByUser()
          }
        }
      }
    },
    searchProjects(nv) {
      this.IsCurrentPageOne = true
      /* project null then call user api as per role */
      if (!this.searchUser?.length) {
        if (nv && !nv.length && this.userInfo && this.userInfo.role === 'SA') {
          this.userList()
        } else {
          this.reportingAndManageByUser()
        }
      }

      if (!this.searchUser?.length && nv && !nv.length) {
        this.projectList()
      }
      if (this.searchUser?.length && nv && !nv.length) {
        if (this.userInfo.role == 'EMP') {
          const allUsersIncluded = this.searchUser.every(userId => this.reportingAndManageByUsers.some(user => user.id === userId))

          if (!allUsersIncluded) this.searchUser = []
          if (this.reportingAndManageByUsers && !this.reportingAndManageByUsers.length) {
            this.reportingAndManageByUser()
          } else {
            this.users = this.reportingAndManageByUsers
          }
        }
        if (this.userInfo.role == 'EMP' && !this.searchUser?.length && nv && !nv.length) {
          this.projectList()
        } else {
          this.UserProjectList()
        }
      }

      /* user project both are null */
      if (this.userInfo && (this.userInfo.role === 'SA' || this.userInfo.is_resource_managing)) {
        /* user project both are null */
        if (!this.searchUser.length && !this.searchProjects.length) {
          this.projectList()
          if (nv && !nv.length && this.userInfo && this.userInfo.role === 'SA') {
            this.userList()
          } else {
            this.reportingAndManageByUser()
          }
        }
      }
    },
    searchTags() {
      this.IsCurrentPageOne = true
    },
    searchBillable() {
      this.IsCurrentPageOne = true
      eventBus.$emit('closeCustomDropDown')
    },
    currentPage: {
      handler(value) {
        if (value) {
          this.timeSheetReport()
        }
      },
    },
    perPage() {
      if (this.currentPage === 1) {
        this.timeSheetReport()
      } else {
        this.currentPage = 1
      }
    },
    groupId() {
      this.IsCurrentPageOne = true
    },
    client_id() {
      this.IsCurrentPageOne = true
    },
  },
  mounted() {
    if (this.userInfo && this.userInfo.role === 'SA') {
      this.filterFunctionCallFromMixin()
    } else {
      this.reportingAndManageByUser()
    }
    this.setFilterValue()
    this.projectList()
  },
  methods: {
    updateDate(obj) {
      this.startDate = obj && obj.startDate ? obj.startDate : null
      this.endDate = obj && obj.endDate ? obj.endDate : null
      this.activeBtn = obj && obj.active ? obj.active : null
      this.IsCurrentPageOne = true
      this.timeSheetReport()
    },

    /**
       * Filters apply
       * */
    applyFilter() {
      if (this.currentPage !== 1 && this.IsCurrentPageOne) {
        this.currentPage = 1
      } else {
        this.timeSheetReport()
      }
    },
    clearFilter() {
      this.currentPage = 1
      this.perPage = 100
      this.startDate = moment().startOf('week').add(1, 'd').format('YYYY-MM-DD')
      this.endDate = moment().endOf('week').add(1, 'd').format('YYYY-MM-DD')
      this.searchBillable = null
      this.groupId = null
      this.client_id = []
      this.searchUser = []
      this.searchProjects = []
      this.searchTags = []
      this.totalTime = null
      this.queryDataUpdate()
      this.reports = []
      this.applyFilter()
    },

    /**
       * Get report data
       */
    async timeSheetReport() {
      this.loader = true

      const input = {
        page: this.currentPage,
        per_page: this.perPage,
        start_date: this.startDate,
        end_date: this.endDate,
        member: this.searchUser,
        project: this.searchProjects,
        tags: this.searchTags,
        billable: this.searchBillable ? this.searchBillable === 'yes' : null,
        client_id: this.client_id,
        group_id: this.groupId,
      }
      // TODO::If no any filter apply then show no data image
      const hasValueSet = (
        (this.searchUser && this.searchUser.length)
        || (this.searchProjects && this.searchProjects.length)
        || (this.searchTags && this.searchTags.length)
        || this.groupId
        || (this.client_id && this.client_id.length)
      )
      if ((this.userInfo.role === 'SA' || this.userInfo.is_resource_managing) && (input.billable == null && !hasValueSet)) {
        this.loader = false
        return
      }

      this.queryDataUpdate()
      const response = await this.getHTTPPostResponse(
        'admin/master/reports/time-sheet-report-sql',
        input,
        false,
      )
      if (response && response.status === 200) {
        const { data } = response
        this.totalCount = data.count
        this.totalTime = data.totalTime

        const keys = Object.keys(data.user_tasks)
        const tasks = []
        keys.forEach(element => {
          data.user_tasks[element].forEach(e => {
            tasks.push(e)
          })
        })

        this.reports = tasks
        this.loader = false
      } else {
        this.loader = false
      }
    },

    /*  filter data */
    async filterFunctionCallFromMixin() {
      if (!Object.values(this.$route.query)) {
        this.userList()
      }
      if (this.$store.state.app.groupList.length === 0) {
        await this.getGroupData()
      }
      if (this.$store.state.app.clientList.length === 0) {
        await this.clientList()
      }
    },
    queryDataUpdate() {
      const obj = {
        member: this.searchUser,
        currentPage: this.currentPage,
        perPage: this.perPage,
        search: this.search,
        startDate: this.startDate,
        endDate: this.endDate,
        project: this.searchProjects,
        tag: this.searchTags ? this.getTagsAsString() : null,
        isBillable: this.searchBillable,
        group: this.groupId ? this.getLabelOfGroup() : null,
        client: this.client_id,
        active: this.activeBtn,
      }
      let summaryObj = {}
      if (this.$route.query.summaryFirst) {
        summaryObj = this.summaryFilters()
      }
      const query = {
        ...obj,
        ...summaryObj,
      }
      this.routeQueryUpdate(query)
    },
    summaryFilters() {
      const obj = {
        summaryGroup: this.$route.query.summaryGroup,
        summaryClient: this.$route.query.summaryClient,
        summaryTag: this.$route.query.summaryTag,
        summaryProjects: this.$route.query.summaryProjects,
        summaryFirst: this.$route.query.summaryFirst,
        summarySecond: this.$route.query.summarySecond,
        summaryThird: this.$route.query.summaryThird,
        summaryStartDate: this.$route.query.summaryStartDate,
        summaryEndDate: this.$route.query.summaryEndDate,
        summaryIsBillable: this.$route.query.summaryIsBillable,
        summaryActive: this.$route.query.summaryActive,
        summaryMemberList: this.$route.query.summaryMemberList,
      }

      return obj
    },
    setFilterValue() {
      if (!this.$route.query) return

      const { query } = this.$route

      // Set search, perPage, and currentPage
      this.search = query.search
      this.perPage = query.perPage || 100
      this.currentPage = query.currentPage || 1

      // Set startDate, endDate,searchUser, searchProjects, searchTags, and searchBillable
      this.activeBtn = query.active || query.summaryActive || this.activeBtn
      this.startDate = query.startDate || query.summaryStartDate || this.startDate
      this.endDate = query.endDate || query.summaryEndDate || this.endDate
      this.searchUser = query.member ? (typeof query.member === 'string' ? [query.member] : query.member) : this.searchUser
      this.searchProjects = query.project ? (typeof query.project === 'string' ? [query.project] : query.project) : this.searchProjects
      this.searchTags = query.tag ? (typeof query.tag === 'string' ? this.tagsArray(query.tag.trim()) : query.tag) : this.searchTags
      this.searchBillable = query.isBillable || query.summaryIsBillable || this.searchBillable

      // Call getGroupId if group is provided
      if (query.group) {
        this.getGroupId(query.group)
      }

      // Call corresponding functions based on conditions
      if (this.searchProjects && this.searchProjects.length) {
        this.reportingAndManageByUser()
      } else if (this.userInfo.role === 'SA') {
        this.userList()
      } else {
        this.reportingAndManageByUser()
      }

      // Set client_id
      this.client_id = query.client ? (typeof query.client === 'string' ? [query.client] : query.client) : this.client_id

      // Call timeSheetReport
      this.timeSheetReport()
    },

    getLabelOfGroup() {
      const group = this.$store.state.app.groupList.find(option => option.value === this.groupId)

      return group && group.label ? group.label : null
    },

    async getGroupId(value) {
      if (this.$store.state.app.groupList.length === 0) {
        await this.getGroupData()
      }
      const group = this.$store.state.app.groupList.find(option => option.label === value)

      this.groupId = group && group.value ? group.value : this.groupId
    },
    getTagsAsString() {
      return this.searchTags.toString()
    },

    // Update Billable task
    async updateTaskBillable(item) {
      const input = {
        id: item.user_task_id,
      }
      const response = await this.getHTTPPostResponse(
        'user-task/update-billable',
        input,
      )
      if (response && response.status === 200) {
        const index = this.reports.findIndex(
          bill => bill.user_task_id === item.user_task_id,
        )
        this.timeSheetReport()
        //   if (this.searchBillable !== null) {
        //     this.reports.splice(index, 1)
        //   } else {
        //     this.reports[index].is_billable = !this.reports[index].is_billable
        //   }
        // } else {
        //   this.timeSheetReport()
        // }
      }
    },
    confirmationFunction(type) {
      this.$swal({
        title: 'Note',
        html: this.userInfo.role === 'SA' || this.userInfo.is_resource_managing ? "<p class='my-1'>Are you sure want to export?</p>  <div class='d-flex justify-content-center'> <div class=' d-flex justify-content-center align-items-center'>  <input type=checkbox id=vehicle1 name=vehicle1  class=input-aleart></input> <label for=vehicle1 class='label-sweet mb-0'> Include name</label></div>  <div class='d-flex justify-content-center align-items-center'><input type=checkbox id=vehicle2 name=vehicle2 checked class='ml-1 input-aleart'></input> <label for=vehicle2 class='label-sweet mb-0'> Only Billable </label></div></div>"
          : "<p class='my-1'>Are you sure want to export?</p>  <div class='d-flex justify-content-center'> <div class=' d-flex justify-content-center align-items-center'>  <input type=checkbox id=vehicle1 name=vehicle1  class=input-aleart></input> <label for=vehicle1 class='label-sweet mb-0'> Include name</label></div>  <div class='d-flex justify-content-center align-items-center'><input type=checkbox id=vehicle2 name=vehicle2  class='ml-1 input-aleart'></input> <label for=vehicle2 class='label-sweet mb-0'> Only Billable </label></div></div>",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn confirm',
          cancelButton: 'btn cancel ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const id = document.getElementById('vehicle1').checked
          const billable = document.getElementById('vehicle2').checked

          this.generateReport(id, type, billable)
        }
      })
    },
    async generateReport(id, type, billable) {
      this.exportLoader = true
      const isName = id === true ? 1 : 0
      const isBillable = billable === true ? 1 : 0

      let URL = `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/reports/time-sheet-export?is_name=${isName}&is_billable=${isBillable}&totalExportTime=${this.totalDailyTime}&`
      URL += `user_id=${this.userInfo.id}&`
      URL += `role=${this.userInfo.role}&`
      if (this.startDate) {
        URL += `start_date=${this.startDate}`
      }
      if (this.endDate) {
        URL += `&end_date=${this.endDate}`
      }
      if (this.searchUser.length) {
        this.searchUser.forEach(element => {
          URL += `&member[]=${element}`
        })
      }
      if (this.searchProjects && this.searchProjects.length) {
        this.searchProjects.forEach(element => {
          URL += `&project[]=${element}`
        })
      }
      if (this.searchTags && this.searchTags.length) {
        this.searchTags.forEach(element => {
          URL += `&tags[]=${element}`
        })
      }
      if (this.client_id && this.client_id.length) {
        this.client_id.forEach(element => {
          URL += `&client_id[]=${element}`
        })
      }
      if (this.groupId) {
        URL += `&group_id=${this.groupId}`
      }
      URL += `&file_type=${type}`

      //  location.href = URL;
      const fileName = `TimeSheet.${type}`
      this.$axios({
        url: URL,
        method: 'GET',
        responseType: 'blob',
      }).then(response => {
        if (type === 'PDF') {
          const fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: 'text/pdf' }),
          )
          const fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', fileName)
          document.body.appendChild(fileLink)
          fileLink.click()
        } else {
          location.href = URL
        }
        this.exportLoader = false
      })
    },
    /* project list */
    async projectList() {
      let input = {}
      let response
      if (this.userInfo && this.userInfo.role === 'SA') {
        response = await this.getHTTPPostResponse(
          'project/log-project-list',
          input,
          false,
        )
      } else {
        input = {
          type: 'projectDetail',
          is_active: true,
        }
        response = await this.getHTTPPostResponse(
          'project/time-log',
          input,
          false,
        )
      }
      if (response && response.data) {
        const { data } = response
        this.projects = data.projects
        if (this.userInfo.role == 'EMP') {
          const allProjectsIncluded = this.searchProjects.every(projectId => this.projects.some(project => project.id === projectId))

          if (!allProjectsIncluded) this.searchProjects = []
        }
      }
    },

    /* project list */
    async UserProjectList() {
      const input = {
        user_ids: !this.searchUser ? [] : this.searchUser,
      }

      const response = await this.getHTTPGetResponse(
        'project/user/user-project-list',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.projects = data.project
      }
    },
    /* user List */
    async userList() {
      const input = {
        sort_field: 'name',
        sort_order: 'asc',
        is_active: 1,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/user/inactive-user',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.users = data.users
      }
    },

    /* Reporting And Manage By Users List */
    async reportingAndManageByUser() {
      const input = {
        project_id: this.searchProjects,
        sort_field: 'name',
        sort_order: 'asc',
      }
      const response = await this.getHTTPPostResponse(
        'timesheet-user-list',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.users = data.user
        this.users.push(this.userInfo)
        if (this.searchProjects && !this.searchProjects.length) {
          this.reportingAndManageByUsers = this.users
        }
        /*  todo 02-02-23  form employee to resolve select member remove after project filter remove */
        if (this.userInfo?.role !== 'SA' && !this.userInfo?.is_resource_managing) {
          this.searchUser = this.searchProjects?.length ? this.searchUser : []
        }
      }
    },
    back() {
      if (this.$route.query.summaryFirst) {
        this.$router.push({
          name: 'summary-report',
          query: this.summaryFilters(),
        }).catch(err => {
        // Ignore the NavigationDuplicated error
          if (err.name !== 'NavigationDuplicated') {
            console.log(err)
          }
        })
      } else {
        this.$router.push({
          name: 'reports',
        }).catch(err => {
        // Ignore the NavigationDuplicated error
          if (err.name !== 'NavigationDuplicated') {
            console.log(err)
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/component-css/approvals.css";
@import "../../assets/scss/component-css/report.css";
</style>
